<template>
  <div>
    <HeaderPanel
      title="Ticket"
      :filter="filter"
      placeholder="Search Subject"
      @search="handleSearch"
      :hasSearch="false"
      @sidebar="sidebarFilter"
      @export="exportExcel"
      @clearFilter="clearFilter"
      searchAdvanced
      isShowClearFilter
      :searchList="[
        { key: 'search', placeholder: 'Subject., Ticket ID.' },
        { key: 'search_member_id', placeholder: 'Member ID.' },
        { key: 'search_name', placeholder: 'Customer Name' },
        { key: 'search_tel_email', placeholder: 'Telephone, Email' },
      ]"
    >
      <!-- hasExport -->
      <template v-slot:filter-title>
        <b-form-checkbox
          v-model="filter.onlyMe"
          value="1"
          unchecked-value="0"
          @change="onOnlyMeChange"
          >Show only assign to me</b-form-checkbox
        >
      </template>

      <template v-slot:content-dropdown>
        <b-dropdown-item
          v-if="$store.state.permission.Permission.response.includes(71)"
          @click="$router.push('ticket-activity/create')"
        >
          <span>Create new</span>
        </b-dropdown-item>
        <b-dropdown-item @click="handleAction(1)">
          <span>Assign to</span>
        </b-dropdown-item>
        <b-dropdown-item @click="handleAction(2)">
          <span>Close</span>
        </b-dropdown-item>
        <b-dropdown-item
          @click="handleAction(3)"
          v-if="$store.state.permission.Permission.response.includes(73)"
        >
          <span>Delete</span>
        </b-dropdown-item>
      </template>
      <template v-slot:extra-button>
        <b-dropdown id="dropdown" right class="btn-mobile ml-2">
          <template #button-content>
            <span class="d-none d-md-inline-flex btn-text mr-1">Export</span>
          </template>
          <b-dropdown-item @click="exportExcel">
            <font-awesome-icon icon="file-export" class="d-md-none" />
            <span class="d-none d-md-block">
              <font-awesome-icon icon="file-export" class="pointer" />
              <span class="btn-text">Export Ticket List</span>
            </span>
          </b-dropdown-item>
          <b-dropdown-item @click="exportExcelDetail">
            <font-awesome-icon icon="file-export" class="d-md-none" />
            <span class="d-none d-md-block">
              <font-awesome-icon icon="file-export" class="pointer" />
              <span class="btn-text">Export Ticket History</span>
            </span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </HeaderPanel>
    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <b-row>
        <b-col>
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="formartedItems"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
            @sort-changed="sortingChanged"
          >
            <template v-slot:cell(ids)="data">
              <b-form-checkbox
                size="lg"
                class="ml-3"
                :value="data.item.id"
                v-model="selected"
                v-if="data.item.deleted != 1"
              ></b-form-checkbox>
            </template>
            <template v-slot:head(ids)="data">
              <b-form-checkbox
                size="lg"
                class="ml-3"
                :ref="data.field.key"
                v-model="selectedAll"
                @change="onChangeSelectedAll"
              ></b-form-checkbox>
            </template>
            <template v-slot:cell(subject_type)="data">
              <div>
                <div>
                  <u
                    @click="
                      $router.push(`/ticket-activity/detail/${data.item.id}`)
                    "
                    class="pointer"
                  >
                    {{ data.item.name }}
                  </u>

                  <span
                    v-if="
                      data.item.sla_status === 1 &&
                      data.item.ticket_status_id === 1
                    "
                    class="badge badge-new badge-pill"
                    >New</span
                  >
                </div>
                <div class="text-small">{{ data.item.ticket_type }}</div>
              </div>
            </template>
            <template v-slot:cell(customer_name)="data">
              <router-link
                :to="`/report/customer/${data.item.user_guid}`"
                style="line-height: 15px"
              >
                <div class="text-bold text-underline">
                  {{ data.item.customer_name }}
                </div>
              </router-link>
              <div class="f-12 text-light-black">
                {{ data.item.member_id }}
              </div>

              <div class="f-12 text-light-black">
                {{
                  data.item.is_company == 0 || !data.item.is_company
                    ? "Personal"
                    : `Company  ${
                        data.item.company_information
                          ? data.item.company_information == "จดทะเบียน"
                            ? " : Register"
                            : ": Not Register"
                          : ""
                      }`
                }}
              </div>
            </template>
            <template v-slot:cell(id)="data">
              <div>
                <div
                  @click="
                    $router.push(`/ticket-activity/detail/${data.item.id}`)
                  "
                >
                  <u class="pointer">#{{ data.item.id }}&nbsp;</u>
                  <br />
                  <u class="pointer"> {{ data.item.name }}</u>
                  <span
                    class="badge badge-danger badge-pill ml-2"
                    v-if="data.item.deleted === 1"
                  >
                    Deleted
                  </span>
                  <span
                    v-else-if="
                      data.item.sla_status === 1 &&
                      data.item.ticket_status_id === 1
                    "
                    class="badge badge-new badge-pill"
                    >New</span
                  >
                </div>
                <div class="text-small">{{ data.item.ticket_type }}</div>
              </div>
            </template>
            <template v-slot:cell(admin_name)="data">
              <div>{{ data.item.admin_name || "-" }}</div>
            </template>
            <template v-slot:cell(created_time)="data">
              <div>{{ data.item.created_time | moment($formatDateNew) }}</div>
              <div class="text-secondary">
                {{ data.item.created_time | moment("HH:mm:ss") }}
              </div>
            </template>
            <template v-slot:cell(updated_time)="data">
              <div>{{ data.item.updated_time | moment($formatDateNew) }}</div>
              <div class="text-secondary">
                {{ data.item.updated_time | moment("HH:mm:ss") }}
              </div>
            </template>
            <template v-slot:cell(status)="data">
              <div
                :class="[
                  { 'text-success': data.item.ticket_status_id == 1 },
                  { 'text-warning': data.item.ticket_status_id == 2 },
                ]"
              >
                {{ data.item.status }}
              </div>
            </template>

            <template v-slot:cell(action)="data">
              <div class="d-flex justify-content-center">
                <b-button variant="link">
                  <router-link :to="`/ticket-activity/detail/${data.item.id}`">
                    <u>Detail</u>
                  </router-link>
                </b-button>
              </div>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <Pagination
        :pageOptions="pageOptions"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
        @handleChangeTake="handleChangeTake"
      />
    </div>
    <SideBarFilter
      ref="sidebarFilter"
      :filter="filter"
      :hideSearchBar="true"
      :hideStatusFilter="false"
      @searchAll="getDataByStatus"
      placeholder="Search product name"
      @clearFilter="clearFilter"
    >
      <template v-slot:filter-option>
        <InputText
          textFloat="Subject / Ticket ID."
          placeholder="Subject., Ticket ID."
          v-model="filter.search"
        />
        <InputText
          textFloat="Member ID."
          placeholder="Member ID."
          v-model="filter.search_member_id"
        />
        <InputText
          textFloat="Customer Name"
          placeholder="Customer Name"
          v-model="filter.search_name"
        />
        <InputText
          textFloat="Telephone / Email"
          placeholder="Telephone, Email"
          v-model="filter.search_tel_email"
        />
        <div class="filter-form">
          <InputTicketType
            :value="filter.type"
            @change="filter.type = $event"
          />
          <InputTicketSource
            title="Source"
            :value="filter.source"
            @change="filter.source = $event"
          />
          <!-- <InputCustomerName
            :value="filter.user_guids"
            @change="filter.user_guids = $event"
          /> -->
          <InputAdminName
            :value="filter.admin_user_guid"
            @change="filter.admin_user_guid = $event"
          />
          <InputSelect
            title="Create Date"
            class="mb-0"
            name="created_time_type"
            v-model="filter.created_time_type"
            v-bind:options="timeOptions"
            valueField="id"
            textField="name"
          />

          <template v-if="filter.created_time_type == 0">
            <div class="ml-3">
              <div class="mb-2">Create Date From</div>

              <div :class="['input-container']">
                <datetime
                  type="date"
                  class="date-picker"
                  placeholder="Please Select Date"
                  format="dd/MM/yyyy"
                  value-zone="local"
                  ref="createdDateFrom"
                  v-model="filter.createdDateFrom"
                >
                </datetime>

                <div
                  :class="'icon-primary text-right'"
                  @click="$refs.createdDateFrom.isOpen = true"
                >
                  <font-awesome-icon
                    icon="calendar-alt"
                    :class="'cursor-default color-primary'"
                    color="#B41BB4"
                  />
                </div>
              </div>
            </div>
            <div class="ml-3">
              <div class="mb-2">Create Date To</div>
              <div class="input-container mt-2">
                <datetime
                  type="date"
                  class="date-picker"
                  placeholder="Please Select Date"
                  format="dd/MM/yyyy"
                  value-zone="local"
                  ref="createdDateTo"
                  v-model="filter.createdDateTo"
                >
                </datetime>
                <div
                  :class="'icon-primary text-right'"
                  @click="$refs.createdDateTo.isOpen = true"
                >
                  <font-awesome-icon
                    icon="calendar-alt"
                    :class="'cursor-default color-primary'"
                    color="#B41BB4"
                  />
                </div>
              </div>
            </div>
          </template>

          <InputSelect
            title="Latest Date"
            class="mb-0"
            name="last_updated_time_type"
            v-model="filter.last_updated_time_type"
            v-bind:options="timeOptions"
            valueField="id"
            textField="name"
          />

          <template v-if="filter.last_updated_time_type == 0">
            <div class="ml-3">
              <div class="mb-2">Latest Date From</div>

              <div :class="['input-container']">
                <datetime
                  type="date"
                  class="date-picker"
                  placeholder="Please Select Date"
                  format="dd/MM/yyyy"
                  value-zone="local"
                  ref="lastUpdatedDateFrom"
                  v-model="filter.lastUpdatedDateFrom"
                >
                </datetime>

                <div
                  :class="'icon-primary text-right'"
                  @click="$refs.lastUpdatedDateFrom.isOpen = true"
                >
                  <font-awesome-icon
                    icon="calendar-alt"
                    :class="'cursor-default color-primary'"
                    color="#B41BB4"
                  />
                </div>
              </div>
            </div>
            <div class="ml-3">
              <div class="mb-2">Latest Date To</div>
              <div class="input-container mt-2">
                <datetime
                  type="date"
                  class="date-picker"
                  placeholder="Please Select Date"
                  format="dd/MM/yyyy"
                  value-zone="local"
                  ref="lastUpdatedDateTo"
                  v-model="filter.lastUpdatedDateTo"
                >
                </datetime>
                <div
                  :class="'icon-primary text-right'"
                  @click="$refs.lastUpdatedDateTo.isOpen = true"
                >
                  <font-awesome-icon
                    icon="calendar-alt"
                    :class="'cursor-default color-primary'"
                    color="#B41BB4"
                  />
                </div>
              </div>
            </div>
          </template>
          <InputStatus
            :value="filter.status"
            @change="filter.status = $event"
          />
        </div>
      </template>
    </SideBarFilter>
    <ModalAdminList ref="adminModal" @select="updateActivityByAction($event)" />
  </div>
</template>

<script>
import HeaderPanel from "@/components/HeaderPanel";
import Pagination from "@/components/Pagination";
import SideBarFilter from "@/components/SideBarFilter";
import ModalAdminList from "@/components/ticket/activity/ModalAdminList";
import InputAdminName from "@/components/ticket/activity/InputAdminName";
import InputTicketType from "@/components/ticket/activity/InputTicketType";
import InputTicketSource from "@/components/ticket/activity/InputTicketSource";
import InputCustomerName from "@/components/ticket/activity/InputCustomerName";
import InputStatus from "@/components/ticket/activity/InputStatus";
import InputSelect from "@/components/inputs/InputSelect";
const moment = require("moment");
var time = null;
export default {
  name: "TicketActivityIndex",
  components: {
    HeaderPanel,
    Pagination,
    SideBarFilter,
    ModalAdminList,
    InputAdminName,
    InputTicketType,
    InputTicketSource,
    InputCustomerName,
    InputStatus,
    InputSelect,
  },
  data() {
    return {
      fields: [
        {
          key: "ids",
          label: "",
          class: "w-1",
        },
        {
          key: "id",
          label: "Ticket Id",
          class: "text-left",
          thStyle: { width: "15%" },
        },

        {
          key: "customer_name",
          label: "Customer Name",
          thStyle: { width: "15%" },
          class: "text-left",
        },
        {
          key: "ticket_source",
          label: "Source",
          class: "text-left",
        },
        {
          key: "telephone",
          label: "Telephone",
          thStyle: { width: "10%" },
        },
        {
          key: "created_by",
          label: "Created By",
          thStyle: { width: "10%" },
        },
        {
          key: "admin_name",
          label: "Admin Name",
        },
        {
          key: "created_time",
          label: "Created Date",
          sortable: true,
        },
        {
          key: "updated_time",
          label: "Last Updated Date",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "action",
          label: "Action",
        },
      ],
      items: [],
      isBusy: false,
      checkAll: false,
      selectAllCb: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      checkboxInactive: false,
      checkboxActive: false,
      filter: {
        type: [], // list int
        user_guids: [], // list string
        admin_user_guid: [], // list string
        source: [], // list string
        created_time_type: 4,
        last_updated_time_type: 4,
        status: [], // list int
        search: "",
        search_member_id: "",
        search_name: "",
        search_tel_email: "",
        page: 1,
        take: 10,
        onlyMe: 0,
        sortColumnId: 0, // 1 = created_time, 2 = updated_time
        sortType: true, // true = ASC, false = DESC
        createdDateFrom: moment().subtract(1, "month").format("YYYY-MM-DD"),
        createdDateTo: moment().format("YYYY-MM-DD"),
        lastUpdatedDateFrom: moment().subtract(1, "month").format("YYYY-MM-DD"),
        lastUpdatedDateTo: moment().format("YYYY-MM-DD"), // Type DateTime
      },
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      selected: [],
      selectedAll: false,
      allItems: [],
      timeOptions: [
        { id: 4, name: "All" },
        { id: 1, name: "This Week" },
        { id: 2, name: "This Month" },
        { id: 3, name: "This Year" },
        { id: 0, name: "Custom" },
      ],
      actionType: 1,
    };
  },
  watch: {
    selected: function () {
      if (this.selected.length == this.allItems.length) {
        this.selectedAll = true;
      } else {
        this.selectedAll = false;
      }
    },
  },
  computed: {
    formartedItems() {
      if (!this.items) return [];
      return this.items.map((item) => {
        item._rowVariant = item.deleted ? "danger border-top-unset" : "";
        return item;
      });
    },
  },
  created: async function () {
    await this.getDefaultAssign();
    await this.getList();

    await this.getAllData();
    if (!time)
      time = setInterval(() => {
        if (!this.isBusy) this.fetchData(true);
      }, 5000);

    // if (!isWait && time) clearInterval(time);
  },
  beforeDestroy() {
    clearInterval(time);
  },
  methods: {
    onOnlyMeChange(value) {
      this.filter = {
        ...this.filter,
        onlyMe: value,
        page: 1,
      };
      this.getList();
    },
    onChangeSelectedAll: async function (e) {
      await this.handleSelectedAll(e);
    },
    handleSelectedAll: async function (e) {
      this.selectedAll = e;
      this.selected = [];
      if (e) {
        await this.getAllData();

        this.allItems.forEach((element) => {
          this.selected.push(element.id);
        });
      }
    },

    getAllData: async function () {
      let filterAll = {
        ...this.filter,
        admin_user_guid: this.filter.admin_user_guid.map((el) => el.user_guid),
      };
      filterAll.page = 1;
      filterAll.pageSize = -1;
      const { data } = await this.axios.post(`/ticket/get_list`, filterAll);
      if (data.result == 1) {
        this.allItems = data.detail.data;
      }
    },
    getList: async function () {
      this.isBusy = true;
      await this.fetchData();
      this.isBusy = false;
    },
    handleSearch(e) {
      this.filter = {
        ...this.filter,
        ...e,
        page: 1,
        pageSize: 10,
      };
      this.getList();
    },
    pagination(value) {
      this.filter.page = value;
      this.getList();
    },
    handleChangeTake(take) {
      this.filter.page = 1;
      this.filter.take = take;
      this.getList();
    },
    getDataByStatus() {
      this.getList();
    },

    clearFilter() {
      this.filter = {
        type: [], // list int
        source: [], // list int
        user_guids: [], // list string
        admin_user_guid: [], // list string
        created_time_type: 4,
        last_updated_time_type: 4,
        status: [], // list int
        search: "",
        search_member_id: "",
        search_name: "",
        search_tel_email: "",
        page: 1,
        take: 10,
        onlyMe: 0,
        sortColumnId: 0, // 1 = created_time, 2 = updated_time
        sortType: true, // true = ASC, false = DESC
        createdDateFrom: moment().subtract(1, "month").format("YYYY-MM-DD"),
        createdDateTo: moment().format("YYYY-MM-DD"),
        lastUpdatedDateFrom: moment().subtract(1, "month").format("YYYY-MM-DD"),
        lastUpdatedDateTo: moment().format("YYYY-MM-DD"), // Type DateTime
      };
      this.$refs.sidebarFilter.hide();
      this.getList();
    },
    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },
    async updateActivityByAction(assignTo) {
      if (this.selected.length === 0) {
        return;
      }
      this.$bus.$emit("showLoading");
      const tickets = this.allItems
        .map((el) => {
          return {
            ticket_id: el.id,
            ticket_form_id: el.ticket_form_id,
          };
        })
        .filter((ticket) => this.selected.includes(ticket.ticket_id));
      const payload = {
        tickets: tickets,
        assignTo: assignTo ? assignTo.user_guid : "",
        action_type: this.actionType, // 1 = assign to , 2 = close
      };

      const { data } = await this.axios.post(`/ticket/action`, payload);

      this.$bus.$emit("hideLoading");
      if (data.result == 1) {
        this.isLoading = false;

        this.successAlert().then(() => {
          this.getList();
          this.selectedAll = false;
          this.selected = [];
        });
      } else {
        this.isLoading = false;
        this.errorAlert(data.message);
      }
    },
    handleAction(type) {
      this.actionType = type;
      if (type == 1) {
        this.$refs.adminModal.show();
      } else {
        this.updateActivityByAction();
      }
    },
    sortingChanged(ctx) {
      this.filter.sortColumnId = ctx.sortBy === "created_time" ? 1 : 2;
      this.filter.sortType = !ctx.sortDesc;
      this.getList();
    },
    async exportExcel(path) {
      let payload = {
        is_all: this.selectedAll ? 1 : 0, // 1 = all, 0 = not all
        tickets: [],
        ...this.filter,
        pageSize: this.filter.take,
        type: this.filter.type.map((el) => el.id),
        source: this.filter.source.map((el) => el.id),
        status: this.filter.status.map((el) => el.id),
        admin_user_guid: this.filter.admin_user_guid.map((el) => el.user_guid),
        user_guids: this.filter.user_guids.map((el) => el.user_guid),
        onlyMe: this.filter.onlyMe == 0 ? this.filter.onlyMe : 1,
        createdDateFrom: moment(this.filter.createdDateFrom).format(
          "YYYY-MM-DD"
        ),
        createdDateTo: moment(this.filter.createdDateTo).format("YYYY-MM-DD"),
        lastUpdatedDateFrom: moment(this.filter.lastUpdatedDateFrom).format(
          "YYYY-MM-DD"
        ),
        lastUpdatedDateTo: moment(this.filter.lastUpdatedDateTo).format(
          "YYYY-MM-DD"
        ), // Type DateTime
      };
      if (!this.selectedAll) {
        const tickets = this.allItems
          .map((el) => {
            return {
              ticket_id: el.id,
              ticket_form_id: el.ticket_form_id,
            };
          })
          .filter((ticket) => this.selected.includes(ticket.ticket_id));
        payload.tickets = tickets;
      }
      if (payload.tickets.length == 0 && !this.selectedAll) payload.is_all = 1;
      this.$bus.$emit("showLoading");
      const res = await this.axios.post(`/ticket/export`, payload, {
        responseType: "blob",
      });
      let data = res;
      var fileURL = window.URL.createObjectURL(new Blob([data.data]));
      var fileLink = document.createElement("a");
      // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", `ticket-report.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.$bus.$emit("hideLoading");
    },
    async exportExcelDetail(path) {
      let payload = {
        is_all: this.selectedAll ? 1 : 0, // 1 = all, 0 = not all
        tickets: [],
        ...this.filter,
        pageSize: this.filter.take,
        type: this.filter.type.map((el) => el.id),
        source: this.filter.source.map((el) => el.id),
        status: this.filter.status.map((el) => el.id),
        admin_user_guid: this.filter.admin_user_guid.map((el) => el.user_guid),
        user_guids: this.filter.user_guids.map((el) => el.user_guid),
        onlyMe: this.filter.onlyMe == 0 ? this.filter.onlyMe : 1,
        createdDateFrom: moment(this.filter.createdDateFrom).format(
          "YYYY-MM-DD"
        ),
        createdDateTo: moment(this.filter.createdDateTo).format("YYYY-MM-DD"),
        lastUpdatedDateFrom: moment(this.filter.lastUpdatedDateFrom).format(
          "YYYY-MM-DD"
        ),
        lastUpdatedDateTo: moment(this.filter.lastUpdatedDateTo).format(
          "YYYY-MM-DD"
        ), // Type DateTime
      };
      if (!this.selectedAll) {
        const tickets = this.allItems
          .map((el) => {
            return {
              ticket_id: el.id,
              ticket_form_id: el.ticket_form_id,
            };
          })
          .filter((ticket) => this.selected.includes(ticket.ticket_id));
        payload.tickets = tickets;
      }
      if (payload.tickets.length == 0 && !this.selectedAll) payload.is_all = 1;
      this.$bus.$emit("showLoading");
      const res = await this.$report.post(`/ticket/export/detail`, payload, {
        responseType: "blob",
      });
      this.$bus.$emit("hideLoading");
      this.confirmAlert({
        message: "Do you want to download the file now?",
        title: "Success !",
        icon: "success",
        confirmButtonText: "Go To Page",
        cancelButtonText: "Close",
      }).then((val) => {
        if (val.isConfirmed) {
          // this.deleteItem(id);
          const routeData = this.$router.resolve({
            name: "Customer Report List",
          });
          window.open(routeData.href, "_blank");
        }
      });
      // let data = res;
      // var fileURL = window.URL.createObjectURL(new Blob([data.data]));
      // var fileLink = document.createElement("a");
      // // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      // fileLink.href = fileURL;
      // fileLink.setAttribute("download", `ticket-detail-report.xlsx`);
      // document.body.appendChild(fileLink);
      // fileLink.click();
    },
    async fetchData(isFetch = false) {
      let tempList = [...this.items];
      const payload = {
        ...this.filter,
        pageSize: this.filter.take,
        type: this.filter.type.map((el) => el.id),
        source: this.filter.source.map((el) => el.id),
        status: this.filter.status.map((el) => el.id),
        admin_user_guid: this.filter.admin_user_guid.map((el) => el.user_guid),
        user_guids: this.filter.user_guids.map((el) => el.user_guid),
        onlyMe: this.filter.onlyMe == 0 ? this.filter.onlyMe : 1,
        createdDateFrom: moment(this.filter.createdDateFrom).format(
          "YYYY-MM-DD"
        ),
        createdDateTo: moment(this.filter.createdDateTo).format("YYYY-MM-DD"),
        lastUpdatedDateFrom: moment(this.filter.lastUpdatedDateFrom).format(
          "YYYY-MM-DD"
        ),
        lastUpdatedDateTo: moment(this.filter.lastUpdatedDateTo).format(
          "YYYY-MM-DD"
        ), // Type DateTime
      };
      const { data } = await this.axios.post(`/ticket/get_list`, payload);

      if (data.result == 1) {
        if (isFetch) {
          const check = tempList.filter((el) => {
            if (data.detail.data.findIndex((a) => a.id == el.id) == -1) {
              return true;
            }
            return false;
          });
          if (check.length > 0) {
            this.items = data.detail.data;
            this.rows = data.detail.total;
            this.$bvToast.toast(`${check.length} New Ticket `, {
              title: "You Have New Ticket",
              autoHideDelay: 5000,
              appendToast: false,
            });
          }
        } else {
          this.items = data.detail.data;
          this.rows = data.detail.total;
        }
      }
    },
    async getDefaultAssign() {
      const res = await this.axios("/ticket/setting");

      this.$nextTick(() => {
        this.filter.onlyMe = Number(res.data.detail.show_assign_to_me);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.text-small {
  font-size: 12px;
}
.filter-form {
  display: flex;
  gap: 8px;
  flex-direction: column;
}

::v-deep .select-custom {
  margin-bottom: 0 !important;
  .main-label {
    font-weight: normal;
    font-size: 14px;
  }
}

::v-deep .header-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.badge-new {
  background-color: rgb(39, 189, 167);
  color: white;
  padding: 0.4em 0.6em;
  font-size: 10px;
  margin-left: 5px;
}
</style>
